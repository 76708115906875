import { ResponseDocument } from "@/types/types";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { TypographyBody, TypographyH4 } from "../ui/Typography";
import { useContext } from "react";
import { ToastContext } from "@/contexts/ToastContext";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store/store";
import { actions as documentActions } from '@/components/Document/documentSlice'
import { deleteDocument } from "../Document/documentThunk";

export const DeleteResourceDialog = ({ resource, open, setOpen }: { resource: ResponseDocument | null, open: boolean, setOpen: (open: boolean) => void }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { showToast } = useContext(ToastContext)

    async function deleteResource(resource: ResponseDocument) {
        setOpen(false)

        const response = await dispatch(deleteDocument(resource))

        if (response.meta.requestStatus === 'fulfilled') {
            showToast({
                variant: 'success',
                description: 'File queued for deletion. This can take a few minutes.',
                dismissable: true
            })

            dispatch(documentActions.deleteFile(resource))
        } else {
            showToast({
                variant: 'error',
                description: `We could not delete ${resource.document_name}`,
                dismissable: true
            })
        }
    }

    if (!resource) return null

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="max-w-full sm:max-w-[504px]">
                <DialogHeader>
                    <DialogTitle className="w-full">
                        <TypographyH4 className="max-w-[400px] overflow-hidden text-ellipsis">
                            {`You are about to delete file ${resource.document_name}`}
                        </TypographyH4>
                    </DialogTitle>
                    <DialogDescription>
                        <TypographyBody>
                            This action cannot be undone.
                        </TypographyBody>
                    </DialogDescription>
                    <DialogFooter className="pt-8">
                        <DialogClose>
                            <Button variant="secondary-destructive" className="w-full" onClick={() => deleteResource(resource)}>Delete file</Button>
                        </DialogClose>
                        <DialogClose>
                            <Button className="w-full">
                                Keep file
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}