import { Integration } from "@/types/types";
import { ChevronLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import IntegrationHeader from "./IntegrationHeader";
import { Button } from "../ui/button";
import { IntegrationAuthentication } from "./IntegrationAuthentication";
import { IntegrationSelection } from "./IntegrationSelection";
import { WarnOnAction } from "../WarnOnAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { fetchIntegrations, setCurrentPage } from "./integrationSlice";
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext";
import { UserContext } from "@/contexts/UserContext";
import { checkIntegrationFlag } from "@/utils/utils";

export interface IntegrationSetupProps {
    integration: Integration
}

export const IntegrationSetupPage = ({ integration }: IntegrationSetupProps) => {
    const integrationStructure = useSelector((state: RootState) => state.integration.integrationStructure)
    const currentPage = useSelector((state: RootState) => state.integration.currentPage)

    const isStructureLoading = integrationStructure[integration.integration_id]?.status === 'loading'

    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div className="flex flex-col gap-2 mx-[88px]">
            <WarnOnAction action={() => {
                if (currentPage === 'authentication') {
                    navigate('/integrations')
                } else {
                    dispatch(setCurrentPage('authentication'))
                }
            }} warn={currentPage === 'file-selection'}>
                <Button variant='tertiary' className={isStructureLoading ? 'opacity-0' : 'opacity-1'}>
                    <div className="flex gap-2">
                        <ChevronLeft className="h-6 w-6 stroke-[1.5px] shrink-0" />

                        {currentPage === 'authentication' && 'All integrations'}
                        {currentPage === 'file-selection' && 'Back'}
                    </div>
                </Button>
            </WarnOnAction>

            <div className="flex flex-col gap-12 max-w-[600px] mx-auto">
                <IntegrationHeader integration={integration} currentPage={currentPage} />

                {currentPage === 'authentication' && <IntegrationAuthentication integration={integration} />}
                {currentPage === 'file-selection' && <IntegrationSelection integration={integration} initialSelection={true} />}
            </div>
        </div>
    )
}

export const IntegrationSetupPageContainer = () => {
    const integrations = useSelector((state: RootState) => state.integration.integrations)

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const integrationFlag = checkIntegrationFlag(ff, user)

    const dispatch = useDispatch<AppDispatch>()
    const { integration_code_name } = useParams();

    useEffect(() => {
        if (!integrations.data) {
            dispatch(fetchIntegrations())
        }
    }, [])

    useEffect(() => {
        document.title = 'Desia AI - Integration Setup'
    }, [])

    if (!integration_code_name) return null

    const selectedIntegration = integrations.data?.find((v) => v.integration_code_name === integration_code_name)

    if (!selectedIntegration) return null

    if (!integrationFlag) return null

    return (
        <IntegrationSetupPage integration={selectedIntegration} />
    )
}