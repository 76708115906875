import type { AssistantStore, Conversation } from "@/types/types";

export function getLiveConversationById({
  requestId,
  conversationId,
  store
}: { requestId: string, conversationId: string, store: AssistantStore }): Conversation {
  const conversation = store.liveConversations?.find(lc =>
    (lc?.conversationId === conversationId) ||
    (lc?.requestIds?.includes(requestId))
  )
  return conversation?.conversation || [];
}