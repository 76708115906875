import { ResponseChartData } from "@/types/types"
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts"
import { ChartHeader } from "./ChartHeader";
import { useEffect, useRef } from "react";
import { formatChartNumber } from "./ResponseChart";

export const ResponseStackedBarChart = ({ id, parsedData, series, options, compact }: {
    id: string
    parsedData: ResponseChartData
    series: ApexAxisChartSeries,
    options: ApexOptions,
    compact?: boolean
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const containerId = crypto.randomUUID()
    const barAmount = parsedData.data.length

    const barOptions: ApexOptions = {
        ...options,
        chart: {
            ...options.chart,
            type: 'bar',
            stacked: true
        },
        yaxis: {
            ...options.yaxis,
            labels: {
                // @ts-expect-error
                ...options.yaxis?.labels,
                offsetX: -16,
            },
        },
        plotOptions: {
            ...options.plotOptions,
            bar: {
                ...options.plotOptions?.bar,
                borderRadius: 0,
                columnWidth: barAmount <= 5 ? '50%' : '80%',
                dataLabels: {
                    ...options.plotOptions?.bar?.dataLabels,
                    total: {
                        enabled: true,
                        formatter: (val: string) => {
                            return formatChartNumber(val);
                        },
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: 400,
                        },
                        offsetY: -4
                    }
                }
            }
        },
    }

    useEffect(() => {
        const parentElement = document.getElementById(`container-${containerId}`)
        const seriesElements = parentElement?.querySelectorAll(`.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series`)
        const seriesDataLength = seriesElements?.[0]?.querySelectorAll('.apexcharts-bar-area').length || 0

        for (let i = 0; i < seriesDataLength; i++) {
            const lastElements: Element[] = []

            seriesElements?.forEach((serie) => {
                const bar = serie.querySelectorAll('.apexcharts-bar-area')[i]
                if (bar && bar.getAttribute('barHeight') !== '0') {
                    lastElements.push(bar)
                }
            })

            const lastElement = lastElements[lastElements.length - 1]

            if (lastElement) {
                lastElement.setAttribute('clip-path', 'inset(0% 0% -11% 0% round 2px 2px 0 0)')
            }
        }
    }, [series, containerId])

    return (
        <div className={`flex flex-col gap-4 p-4 ${compact ? 'mt-8' : 'mt-10'} relative group`}>
            <ChartHeader chartRef={ref} parsedData={parsedData} compact={compact} />

            <div id={`container-${containerId}`} className="relative w-full h-[300px]">
            <div className={`absolute max-w-full ${barAmount > 15 ? 'w-fit' : 'w-full'} laptop:max-w-[1200px] laptop:left-[50%] laptop:-translate-x-[50%] overflow-x-scroll laptop:overflow-x-visible overflow-y-visible h-[300px]`}>
            <div ref={ref} id={`chart-container-${id}`} className={`${barAmount > 30 ? 'w-[1200px]' : barAmount > 15 ? 'w-[1000px]' : 'w-full'} h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}>
                        <Chart
                            options={barOptions}
                            series={series}
                            type='bar'
                            width='100%'
                            height='100%'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}