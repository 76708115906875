import { MessageCircleMore } from "lucide-react"
import { TypographyBody } from "../ui/Typography"
import { ChatList } from "../ChatList"
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { memo, useEffect, useMemo, useRef, useState } from "react";

export const PastChats = memo(() => {
    const [shouldStayOpen, setShouldStayOpen] = useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const assistantStore = useSelector((state: RootState) => state.assistant)
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect()
                const isWithin =
                    e.clientX >= rect.left &&
                    e.clientX <= rect.right &&
                    e.clientY >= rect.top &&
                    e.clientY <= rect.bottom

                if (!isWithin && !dropdownOpen) {
                    setShouldStayOpen(false)
                }
            }
        };

        window.addEventListener("mousemove", handleMouseMove)

        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [dropdownOpen])

    return (
        <div className="fixed top-[68px] left-[120px] group z-[5] max-w-[400px]" ref={containerRef}>
            <div className={`${shouldStayOpen ? 'hidden' : 'flex'} group-hover:!hidden gap-2 px-6 py-1 rounded-full bg-system-secondary border border-system-border-regular`}>
                <MessageCircleMore className="size-6 shrink-0 stroke-[1.5px]" />

                <TypographyBody isStrong={true}>
                    Past chats
                </TypographyBody>
            </div>

            <div
                className={`${shouldStayOpen ? 'flex' : 'hidden'} group-hover:!flex flex-col gap-0 rounded-t-[16px] border border-system-border-regular bg-system-surface`}
                onMouseEnter={() => setShouldStayOpen(true)}
            >
                <div className="flex gap-2 px-6 py-1 bg-system-hover rounded-t-[16px]">
                    <MessageCircleMore className="size-6 shrink-0 stroke-[1.5px]" />

                    <TypographyBody isStrong={true}>
                        Past chats
                    </TypographyBody>
                </div>
                <div className="px-6 overflow-y-scroll max-h-[calc(100vh-68px-36px)] pt-4">
                    <ChatList
                        threads={assistantStore.list}
                        loading={assistantStore.list.length === 0}
                        error={null}
                        type='past-chat'
                        onDropdownOpen={(v) => {
                            setDropdownOpen(v)
                        }}
                    />
                </div>
            </div>
        </div>
    )
})

export const PastChatsContainer = () => {
    const pastChats = useMemo(() => {
        return <PastChats />
    }, [])
    // return null
    return pastChats
}