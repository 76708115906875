import { configureStore, combineSlices } from '@reduxjs/toolkit';
import { docGenSlice } from '../components/DocGen/docGenSlice';
import { userSlice } from '../features/userSlice';
import { integrationSlice } from '../components/Integration/integrationSlice'
import { dossierSlice } from '../components/Dossier/dossierSlice'
import { documentSlice } from '../components/Document/documentSlice';
import { assistantSlice } from '../components/Assistant/assistantSlice';
import { socketIOMiddleware } from './middleware';
import { socketConnection } from '@/utils/SocketConnection';

const rootReducer = combineSlices(
  docGenSlice,
  userSlice,
  integrationSlice,
  dossierSlice,
  documentSlice,
  assistantSlice
);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketIOMiddleware(socketConnection)),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
