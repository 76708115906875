import { ChatSummary, DESIA_EVENT, RequestChatDelete, WebSocketRequestWrapper } from "@/types/types";
import { useNavigate } from "react-router-dom";
import { TypographyBody, TypographyH4, TypographyLabel } from "../ui/Typography";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { getDossierIcon } from "@/utils/components";
import { CustomTooltip } from "../CustomTooltip";
import { useSocketQuery } from "@/hooks/useSocketQuery";
import { getTimestamp } from "@/utils/utils";
import { EllipsisVertical } from "lucide-react";
import { DropdownMenuItem, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { fetchDossierDetail } from "../Dossier/dossierSlice";
import { actions as assistantActions } from '../Assistant/assistantSlice';

export const Thread = memo(({ thread, type, onDropdownOpen }: { thread: ChatSummary, type: 'chat' | 'dossier' | 'past-chat', onDropdownOpen?: (open: boolean) => void }) => {
    const dossiers = useSelector((state: RootState) => state.dossier.dossiers)

    const dossier = dossiers.data?.find((v) => v.id === thread.dossierId)

    const dispatch = useDispatch<AppDispatch>();

    const [openDialog, setOpenDialog] = useState(false)

    const navigate = useNavigate()
    const appDispatch = useDispatch<AppDispatch>()

    const { executeQuery } = useSocketQuery({
        event: DESIA_EVENT.CHAT_DELETE,
        request: {
            requestId: `chat:delete_${thread.conversationId}`,
            timestamp: getTimestamp(),
            params: {
                conversationId: thread.conversationId
            }
        },
        options: {
            manual: true,
            callback: (response) => {
                if (!response.error && response.requestId.includes(thread.conversationId)) {
                    dispatch(assistantActions.chatDelete({
                        conversationId: thread.conversationId
                    }))

                    if (type === 'dossier' && thread.dossierId) {
                        appDispatch(fetchDossierDetail(thread.dossierId))
                    }
                }

            }
        },
    })

    const truncatedQuery = thread.query.length > 30 ? `${thread.query.slice(0, 30)}...` : thread.query

    const handleNavigate = () => {
        navigate(thread.dossierId ? `/dossier/${thread.dossierId}/assistant/conversation/${thread.conversationId}` : `/assistant/conversation/${thread.conversationId}`)
    }

    const handleDelete = useCallback(() => {
        const request: WebSocketRequestWrapper<RequestChatDelete> = {
            requestId: `chat:delete_${thread.conversationId}`,
            timestamp: getTimestamp(),
            params: {
                conversationId: thread.conversationId
            },
        }

        executeQuery({
            event: DESIA_EVENT.CHAT_DELETE,
            request: request
        })
    }, [thread.conversationId, executeQuery])

    const customTooltip = useMemo(() => {
        if (!dossier) return null
        return <CustomTooltip
            id={`chat-dossier-button-${thread.conversationId}`}
            className="!py-1 !px-3 !rounded-sm"
            largeArrow={false}
        >
            <TypographyLabel>
                {dossier?.subject || 'Dossier'}
            </TypographyLabel>
        </CustomTooltip>
    }, [thread.conversationId, dossier?.subject])

    const dropdown = useMemo(() => {
        return <DropdownMenu onOpenChange={(v) => {
            onDropdownOpen?.(v)
        }}>
            <DropdownMenuTrigger className="flex items-center" asChild>
                <Button variant='tertiary'>
                    <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" id="delete-thread-dropdown">
                <DropdownMenuItem
                    variant='tertiary-destructive'
                    onClick={(e) => {
                        e.stopPropagation()
                        setOpenDialog(true)
                    }}
                >
                    Delete chat
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    }, [])

    const dialog = useMemo(() => {
        return <Dialog open={openDialog} onOpenChange={(v) => setOpenDialog(v)}>
            <DialogContent className="max-w-full sm:max-w-[504px]">
                <DialogHeader>
                    <DialogTitle className="w-full">
                        <TypographyH4 className="line-clamp-2 w-full">
                            {`You are about to delete “${truncatedQuery}” chat`}
                        </TypographyH4>
                    </DialogTitle>
                    <DialogDescription>
                        <TypographyBody>
                            This action cannot be undone.
                        </TypographyBody>
                    </DialogDescription>
                    <DialogFooter className="pt-8">
                        <DialogClose>
                            <Button variant="secondary-destructive" className="w-full" onClick={() => {
                                handleDelete()
                            }}>Delete chat</Button>
                        </DialogClose>
                        <DialogClose>
                            <Button className="w-full">
                                Keep chat
                            </Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    }, [openDialog, truncatedQuery, handleDelete])

    return (
        <div className="flex gap-2 items-center px-3 py-2 bg-system-secondary border border-system-border-light rounded-lg cursor-pointer">
            <Button variant='tertiary' className="justify-start w-[calc(100%-36px)]" size='fit' onClick={() => handleNavigate()}>
                <TypographyBody isStrong={true} className="overflow-hidden text-ellipsis w-full text-left">
                    {thread.query}
                </TypographyBody>
            </Button>

            {dossier && type !== 'dossier' && (
                <div className="flex gap-2 items-center" data-tooltip-id={`chat-dossier-button-${thread.conversationId}`} onClick={(e) => {
                    e.stopPropagation()
                    navigate(`/dossier/${dossier.id}`)
                }}>
                    <Button variant='tertiary'>
                        <div className="size-6 flex items-center justify-center rounded-[2.4px] border-[0.6px] border-system-border-regular bg-system-surface-light shrink-0">
                            {getDossierIcon(dossier.icon, '!size-4')}
                        </div>
                    </Button>
                </div>
            )}

            {customTooltip}

            {dropdown}

            {dialog}
        </div >
    )
})