import { ToastContext } from "@/contexts/ToastContext"
import { UserContext } from "@/contexts/UserContext"
import { Citation, SourceDocument, SourceType, SystemMessage } from "@/types/types"
import { filterDocumentsByCited, getGlobalUniqueDocuments } from "@/utils/components"
import { memo, ReactNode, useContext, useMemo, useState } from "react"
import { checkIfStringHasContent, normaliseDocumentId, notEmpty } from "@/utils/utils"
import { captureFeedback } from "@sentry/react"
import { PlannerSteps } from "../PlannerSteps"
import { PreviewSources } from "../Assistant/PreviewSources"
import { FinalAnswer } from "../Assistant/FinalAnswer"
import { Button } from "../ui/button"
import { Check, Copy, RotateCcw, Sparkles, ThumbsDown, ThumbsUp } from "lucide-react"
import { TypographyBody, TypographyLabel, TypographyP } from "../ui/Typography"
import ArrowRight from "@/assets/ArrowRight"
import { FeedbackDialog } from "../FeedbackDialog"
import { handleError } from "@/utils/handleError"
import { compiler } from "markdown-to-jsx"
import { renderToStaticMarkup } from "react-dom/server"
import { StyledTable } from "../Assistant/StyledTable"
import { StyledH4 } from "../Assistant/StyledH4"
import { embedCharts, getChartIds } from "@/utils/embedCitations"
import { toPng } from "html-to-image"

function orderFileFirst(documentIds: string[]) {
    const ordered = documentIds.map(id => {
        return {
            id,
            nId: normaliseDocumentId(id)
        }
    }).sort((a, b) => {
        // internal documents ordered first
        // followed by web links when citation
        // references both sources
        if (a.nId.isFile && b.nId.isWeb) return -1;
        if (a.nId.isWeb && b.nId.isFile) return 1;
        return 0;
    }).map(dd => dd.id);
    return ordered;
}

export function orderDocumentsByCitation(documents: SourceDocument[], citations: Citation[]) {
    try {
        const documentIds = documents.map(d => d.document_id);
        const dIDs = documents.map(d => normaliseDocumentId(d.document_id).id);

        const rankedCitationIds = [...citations]
            .sort((a, b) => a.start - b.start)
            .map(c => orderFileFirst(c.document_ids))
            .flat()
            .map(d => normaliseDocumentId(d).id)
            .filter((id) => dIDs.includes(id))
            .reduce((acc, cur) => {
                if (acc.includes(cur)) {
                    return acc;
                }
                return [...acc, cur];
            }, [] as string[]);

        const rankedDocuments = rankedCitationIds
            .map(nId => {
                const cId = rankedCitationIds.indexOf(nId);
                const rank = cId === -1 ? Infinity : cId;
                return {
                    rank,
                    nId,
                }
            })
            .sort((a, b) => {
                if (!isFinite(a.rank) && !isFinite(b.rank)) {
                    if (!a.nId.includes("web") && b.nId.includes("web")) return 1;
                    if (a.nId.includes("web") && !b.nId.includes("web")) return -1;
                    return 0;
                }
                return a.rank - b.rank;
            })
            .map(o => {
                const d = documents.find(dd => normaliseDocumentId(dd.document_id).id === o.nId);
                return d;
            })
            .filter(Boolean);

        const rankedDocumentIds = rankedDocuments.map(d => d?.document_id);
        // the remaining documents are unordered
        // this occurs when citations are streamed back sequentially
        // still, apply basic sort rule to show internal files first
        const unrankedDocuments = documentIds
            .filter(id => !rankedDocumentIds.includes(id)).sort((a, b) => a.localeCompare(b))
            .map(id => documents.find(d => d.document_id === id))
            .filter(Boolean);

        const orderedDocuments = [...rankedDocuments, ...unrankedDocuments].filter(notEmpty);
        return orderedDocuments;
    } catch (e) {
        handleError(e)
        return documents;
    }
}

const SystemChatMessage = memo(({
    onFollowUpQuestionClick,
    onRetry,
    message,
    compact,
    showFollowUpQuestions,
    canRetry,
    question,
    sourceType,
    openedCitation,
    onCitationOpen,
}: {
    onFollowUpQuestionClick: (question: string) => void,
    onRetry: () => void,
    message: SystemMessage,
    compact?: boolean
    showFollowUpQuestions?: boolean,
    canRetry: boolean,
    question: string,
    sourceType: SourceType,
    openedCitation?: Citation | null;
    onCitationOpen?: (citation: Citation | null, scrollTop: number) => void;
}) => {
    const { user } = useContext(UserContext)
    const { showToast } = useContext(ToastContext)

    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
    const [copySuccess, setCopySuccess] = useState(false)

    const isFinished = message.data.isFinished;
    const loadingAnswer = !message?.data?.text;

    const allDocuments = isFinished ? filterDocumentsByCited(message.data.documents || [], message.data.citations || []) : (message.data.documents || []);
    const filteredDocuments = getGlobalUniqueDocuments(allDocuments)
    const sortedDocuments = orderDocumentsByCitation(filteredDocuments, message.data.citations || []);
    const previewSourcesDocuments = (checkIfStringHasContent(message.data.text || '') ? sortedDocuments : []).filter(d => !d.document_id.includes("python"));
    
    const plannerSteps = (message.data.plan?.steps || []).map(s => ({
        description: s.description,
        tool: s.next_tools,
        answer: s.answer,
        doc_ref: s.doc_ref
    }))
    const plannerDocuments = message.data.planDocuments || null;
    const showPlanner = !!message.data.plan; // fixme
    const followUpQuestions = (message.data.followUpQuestions || [])

    const style = `flex justify-center items-center max-w-[710px] ${compact ? 'font-label' : 'font-body'}`

    const sendPositiveFeedback = () => {
        const tags: Record<string, any> = {
            is_positive: true
        }

        captureFeedback(
            {
                message: '',
                email: user?.email
            },
            {
                captureContext: {
                    tags: tags
                },
                includeReplay: false,
            },
        )

        showToast({
            variant: 'success',
            description: 'Feedback sent. Thank you for your feedback!',
            dismissable: true
        })
    }

    const applyMinimalInlineStyles = (element: Element) => {
        const essentialStyles = [
            'width',
            'height',
            'border',
            'border-color',
            'border-width',
            'border-style',
            'padding',
            'color',
            'font-family',
            'font-size',
            'font-weight',
            'font-style',
            'text-align',
            'line-height',
            'background-color',
        ]

        if (element.nodeType !== Node.ELEMENT_NODE) return

        const computedStyles = window.getComputedStyle(element)
        const inlineStyles = essentialStyles
            .map((prop) => `${prop}: ${computedStyles.getPropertyValue(prop)};`)
            .join(' ');

        element.setAttribute('style', inlineStyles);
        Array.from(element.children).forEach(applyMinimalInlineStyles);
    };

    const copyResponse = async () => {
        const text = message.data.text || ''

        const chartImages: { [id: string]: ReactNode } = {}

        const compiledText = compiler(embedCharts({ text }), {
            overrides: {
                p: {
                    component: ({ children }: { children: ReactNode }) =>
                        <>
                            <TypographyP className={`mb-2 mt-6 first:!mt-0`}>{children}</TypographyP>
                        </>
                },
                table: {
                    component: ({ children }: { children: ReactNode }) =>
                        <>
                            <StyledTable>{children}</StyledTable>
                        </>
                },
                h1: {
                    component: StyledH4
                },
                h2: {
                    component: StyledH4
                },
                h3: {
                    component: StyledH4
                },
                h4: {
                    component: StyledH4
                },
                h5: {
                    component: StyledH4
                },
                h6: {
                    component: StyledH4
                },
                li: {
                    component: ({ children }: { children: ReactNode }) => <li>{children}</li>
                },
                strong: {
                    component: ({ children }: { children: ReactNode }) => <span className={`text-system-primary font-medium`}>{children}</span>
                },
                pre: {
                    component: ({ children }: { children: ReactNode }) => <pre>{children}</pre>
                },
                Chart: {
                    component: ({ id }: { id: string }) => {
                        return id
                    }
                }
            },
            wrapper: 'span',
            forceBlock: true,
        })

        const chartIds = getChartIds(text)

        if (chartIds.length > 0) {
            const chartImagesPromises = chartIds.map(async (id) => {
                const chart = document.getElementById(`chart-container-${id}`)
                if (!chart) return false
                const dataUrl = await toPng(chart, { cacheBust: true, backgroundColor: 'white', skipFonts: true })
                const img = new Image()
                img.src = dataUrl
                chartImages[id] = <img src={dataUrl} />
                return true
            });

            await Promise.all(chartImagesPromises)
        }

        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = renderToStaticMarkup(compiledText)
        document.body.appendChild(tempDiv);

        applyMinimalInlineStyles(tempDiv)

        let styledText = tempDiv.innerHTML

        Object.entries(chartImages).forEach((v) => {
            styledText = styledText.replaceAll(v[0], renderToStaticMarkup(v[1]))
        })

        document.body.removeChild(tempDiv)

        await navigator.clipboard.write([
            new ClipboardItem({
                'text/html': new Blob([styledText], { type: 'text/html' }),
                'text/plain': new Blob([renderToStaticMarkup(text)], { type: 'text/plain' }),
            }),
        ])

        setCopySuccess(true)
        setTimeout(() => {
            setCopySuccess(false)
        }, 3000)
    }

    return (
        <div className={style}>
            <div className="w-full">
                {showPlanner && (
                    <div className="mt-4 flex-1 w-full">
                        <PlannerSteps plannerSteps={plannerSteps} plannerDocuments={plannerDocuments} sourceType={sourceType} />
                    </div>
                )}
                <div className="mt-4 w-full">
                    <PreviewSources documents={previewSourcesDocuments} compact={compact} maxIcons={3} message={message} loading={!isFinished} sourceType={sourceType} />
                </div>
                <div className="mt-6 mx-auto">
                    <div className={compact ? '' : "mx-6"}>
                        <FinalAnswer
                            isLoading={loadingAnswer && isFinished !== true}
                            isComplete={!!isFinished}
                            text={message.data?.text || ""}
                            citations={message.data.citations || []}
                            documents={sortedDocuments}
                            compact={compact}
                            openedCitation={openedCitation}
                            onCitationOpen={onCitationOpen}
                        />
                    </div>

                    {isFinished && (
                        <div className={`flex gap-5 justify-end ${compact ? '' : "mx-6"} pt-1`}>
                            <Button variant='tertiary' onClick={copyResponse}>
                                <div className="flex gap-2 items-center">
                                    {copySuccess ?
                                        <Check className="size-6 shrink-0 stroke-[1.5px]" />
                                        :
                                        <Copy className="size-6 shrink-0 stroke-[1.5px]" />
                                    }

                                    {copySuccess ? 'Copied' : 'Copy'}
                                </div>
                            </Button>

                            {canRetry && (
                                <Button variant='tertiary' onClick={onRetry}>
                                    <div className="flex gap-2 items-center">
                                        <RotateCcw className="size-6 shrink-0 stroke-[1.5px]" />

                                        Retry
                                    </div>
                                </Button>
                            )}

                            <div className="flex gap-2 items-center">
                                <Button variant='tertiary' onClick={sendPositiveFeedback}>
                                    <ThumbsUp className="size-6 shrink-0 stroke-[1.5px]" />
                                </Button>

                                <Button variant='tertiary' onClick={() => setOpenFeedbackDialog(true)}>
                                    <ThumbsDown className="size-6 shrink-0 stroke-[1.5px]" />
                                </Button>
                            </div>
                        </div>
                    )}

                    {followUpQuestions.length > 0 && showFollowUpQuestions && (
                        <div className={`flex flex-col gap-4 ${compact ? 'mt-8' : 'mt-12'} ${compact ? '' : 'mx-6'}`}>
                            <div className="flex gap-2 items-center">
                                <Sparkles className="w-6 h-6 shrink-0 stroke-[1.5px] stroke-system-body" />

                                <TypographyLabel className="text-system-body">
                                    Suggestions
                                </TypographyLabel>
                            </div>
                            <div className={`flex flex-col gap-2 ${compact ? 'pb-4' : 'pb-[60px]'}`}>
                                {[...followUpQuestions].splice(0, 3).map((question, index) => (
                                    <div key={`follow-up-question-${index}`}>
                                        {index != followUpQuestions.length && index !== 0 && (
                                            <div className="w-full h-[1px] bg-system-border-light mb-2"></div>
                                        )}

                                        <Button variant='tertiary' size='fit' className="flex gap-2 cursor-pointer w-full" onClick={() => onFollowUpQuestionClick(question)} disabled={!isFinished}>
                                            <TypographyBody isStrong={true} className="text-system-primary whitespace-pre-wrap text-left">
                                                {question}
                                            </TypographyBody>

                                            <ArrowRight className="w-6 h-6 ml-auto shrink-0" />
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <FeedbackDialog open={openFeedbackDialog} setOpen={setOpenFeedbackDialog} message={message} question={question} />
        </div>)
})

export const SystemChatMessageContainer = (props: {
    onFollowUpQuestionClick: (question: string) => void,
    onRetry: () => void,
    message: SystemMessage,
    compact?: boolean
    showFollowUpQuestions?: boolean,
    canRetry: boolean,
    question: string,
    sourceType: SourceType,
    openedCitation?: Citation | null;
    onCitationOpen?: (citation: Citation | null, scrollTop: number) => void;
}) => {
    const messageContainsOpenedCitation = useMemo(() => {
        return props.openedCitation && props.message?.data?.citations?.find((v: Citation) => {
            return props.openedCitation?.end === v?.end &&
                props.openedCitation?.start === v.start &&
                props.openedCitation?.text === v.text
        })
    }, [props.openedCitation])

    const message = useMemo(() => {
        return <SystemChatMessage {...props} />
    }, [props.message, props.showFollowUpQuestions, props.canRetry, messageContainsOpenedCitation])

    return message
}

export default SystemChatMessage