import { useEffect, useState, useRef, useContext, useCallback, memo, useMemo } from "react";
import { ErrorMessage } from "../ErrorMessage";
import { useBlocker } from "react-router-dom";
import { AssistantAskMode, ChatMessage, Dossier, QueryState, SourceConnector, SystemMessage, UserMessage, StreamStatus, DossierDetail, Citation, SourceDocument } from "../../types/types";
import { ChatBox } from "./ChatBox";
import { UserContext } from "@/contexts/UserContext";
import Divider from "../ui/divider";
import { WarnOnNavigate } from "../WarnOnNavigate";
import { DossierBreadcrumb } from "../Dossier/DossierBreadcrumb";
import { TypographyBody } from "../ui/Typography";
import texture from '../../assets/bg-texture.png'
import { useSelector, useDispatch } from 'react-redux';
import type { RootState, AppDispatch } from '../../store/store';
import { actions as assistantActions } from './assistantSlice';
import { deleteLastMessage } from "./assistantThunk";
import UserChatMessage from "../Chat/UserChatMessage";
import { SystemChatMessageContainer } from "../Chat/SystemChatMessage";
import { filterDuplicateHighlights, getFileId, getUniqueDocumentIds, mapSourceHighlights } from "@/utils/utils";
import { handleError } from "@/utils/handleError";
import { Sources } from "./Sources";
import { DocumentPreviewContainer } from "../Resources/DocumentPreview";
import { filterDocumentsByCited, getGlobalUniqueDocuments } from "@/utils/components";
import { PastChatsContainer } from "./PastChats";

interface IProps {
    handleAsk: (args: { message: string, mode: AssistantAskMode }) => void;
    conversation: ChatMessage[];
    queryState: QueryState;
    sources: SourceConnector[]
    dossier?: Dossier
    dossierDetail?: DossierDetail
    title?: string
}

export const Conversation = memo(({
    handleAsk,
    conversation,
    queryState,
    sources,
    dossier,
    dossierDetail,
    title,
}: IProps) => {
    const { settings, updateSettings } = useContext(UserContext);
    const [hasRendered, setHasRendered] = useState(false);
    const [isChatBoxCoveringText, setIsChatBoxCoveringText] = useState(false);
    const [shouldResetBlocker, setShouldResetBlocker] = useState(true);
    const [prevScrollY, setPrevScrollY] = useState(0);
    const [shouldShowBreadcrumb, setShouldShowBreadcrumb] = useState(true);
    const [isInitialScroll, setIsInitialScroll] = useState(true);
    const [openedCitation, setOpenedCitation] = useState<Citation | null>(null);
    const [openedCitationScrollTop, setOpenedCitationScrollTop] = useState(0);
    const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(null);
    const [selectedExtractIndex, setSelectedExtractIndex] = useState<{ [id: string]: number }>({})
    const [dossierBreadcrumbPosition, setDossierBreadcrumbPosition] = useState<DOMRect | null>(null)

    const assistantStore = useSelector((state: RootState) => state.assistant);
    const dispatch = useDispatch<AppDispatch>();

    const blocker = useBlocker(({ currentLocation, nextLocation }) => assistantStore.state.streamStatus !== 'Ready' && conversation.at(-1)?.role === 'system' && currentLocation.pathname !== nextLocation.pathname);

    const conversationBottomMarginRef = useRef<HTMLDivElement>(null);
    const conversationBottomRef = useRef<HTMLDivElement>(null);
    const sourceContainerRef = useRef<HTMLDivElement>(null);
    const dossierBreadcrumbRef = useRef<HTMLDivElement>(null);

    const lastMessage = conversation.length > 0 ? conversation[conversation.length - 1] : null;

    const askExpired = false; // fixme: checkAskExpired(lastMessage);
    const isBusy = (assistantStore.state.streamStatus !== 'Ready') && (!queryState.error) && !askExpired;
    const sourceType = dossier ? 'dossier' : 'ask'

    const allDocuments = useMemo(() => {
        return Array.from(new Set(conversation.filter((v): v is SystemMessage => Boolean(v)).flatMap((v) => v.data?.documents || [])))
    }, [conversation])

    const citationDocuments = useMemo(() => {
        return openedCitation ? allDocuments.filter(d => {
            const uniqueIds = getUniqueDocumentIds(openedCitation.document_ids)
            try {
                let id = "";
                if (d.document_id.includes("python")) {
                    return false
                }

                if (d.document_id.startsWith("web-search")) {
                    id = d.document_id
                } else {
                    id = d.document_id.split("_")[0]
                }

                return uniqueIds.includes(id)
            } catch (e) {
                handleError(e)
                return false
            }
        }) : []
    }, [openedCitation, allDocuments])

    const uniqueCitationDocuments = useMemo(() => {
        const message = conversation.filter((v): v is SystemMessage => Boolean(v)).find((v) => {
            return v.data?.citations?.find((v) => {
                return v.end === openedCitation?.end &&
                    v.start === openedCitation.start &&
                    v.text === openedCitation.text
            })
        })

        const filteredDocuments = filterDocumentsByCited(message?.data?.documents || [], openedCitation ? [openedCitation] : [])
        const nonChartDocuments = getGlobalUniqueDocuments(filteredDocuments, false).filter((v) => !v.document_id.includes('chart'))
        const filteredHighlights = filterDuplicateHighlights(nonChartDocuments)
        return filteredHighlights
    }, [citationDocuments, openedCitation])

    const sourceContainerWidth = sourceContainerRef.current?.getBoundingClientRect().width || 0
    const sourceContainerRight = sourceContainerRef.current?.getBoundingClientRect().right || 0

    const openedCitationHighlights = useMemo(() => {
        const selectedHighlights = openedCitation?.highlights?.filter((v) => {
            if (v.file_id.startsWith('web')) {
                return v.file_id === selectedSource?.document_id
            }
            return getFileId(v.file_id) === getFileId(selectedSource?.document_id || '')
        })
        const mappedHighlights = mapSourceHighlights(selectedHighlights || [])
        return mappedHighlights
    }, [openedCitation, selectedSource])

    const openedCitationResource = useMemo(() => {
        if (!selectedSource) return null
        return {
            extracts: openedCitationHighlights,
            document_type_friendly: selectedSource.doc_metadata?.document_type_friendly || '',
            id: selectedSource.document_id,
            title: selectedSource.title,
            url: selectedSource.url || '',
            document_link: selectedSource.doc_metadata?.external_link,
            document_source: selectedSource.doc_metadata?.document_source,
            document_updated_at_desia: selectedSource.doc_metadata?.document_updated_at_desia,
            document_updated_at_source: selectedSource.doc_metadata?.document_updated_at_source,
            document_created_at_source: selectedSource.doc_metadata?.document_created_at_source,
            document_source_details: selectedSource.doc_metadata?.document_source_details,
            document_source_path: selectedSource.doc_metadata?.document_source_path
        }
    }, [selectedSource])

    useEffect(() => {
        if (conversation.length > 0 && !isBusy && !hasRendered) {
            conversationBottomRef.current?.scrollIntoView({ behavior: 'instant', block: 'end' })

            setIsInitialScroll(false)
        } else if (conversation.length > 0) {
            setHasRendered(true)
        }
    }, [conversation])

    useEffect(() => {
        setHasRendered(false)
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const mainContainer = document.querySelector('.main-container-new')
            const scrollTop = mainContainer?.scrollTop || 0

            const scrolledTo = window.scrollY + window.innerHeight
            const clientWidth = document.body.clientWidth
            const emptyHeight = clientWidth > 568 ? clientWidth > 768 ? 160 : 80 : 40

            if (document.body.scrollHeight - emptyHeight < scrolledTo) {
                setIsChatBoxCoveringText(true)
            } else {
                setIsChatBoxCoveringText(false)
            }

            if (!isInitialScroll) {
                if (scrollTop > prevScrollY) {
                    setShouldShowBreadcrumb(false)
                } else {
                    setShouldShowBreadcrumb(true)
                }
            }
            setPrevScrollY(mainContainer?.scrollTop || 0)
        }

        window.addEventListener('scroll', handleScroll, { capture: true })

        return () => {
            window.removeEventListener('scroll', handleScroll, { capture: true })
        }
    }, [prevScrollY, isInitialScroll])


    useEffect(() => {
        const lastUserMessage = [...conversation].reverse().find((v) => v.role === 'system') as SystemMessage

        let mode = settings.assistant.mode

        if (lastUserMessage?.data.plan) {
            mode = 'expert'
        } else {
            mode = 'simple'
        }

        updateSettings({
            settings: {
                ...settings,
                assistant: {
                    ...settings.assistant,
                    mode: mode
                }
            }
        })
    }, [conversation])

    useEffect(() => {
        dispatch(assistantActions.setStreamStatusWithMessage({ message: lastMessage, sourceConnectors: sources }))
    }, [lastMessage, sources])

    useEffect(() => {
        return () => {
            if (settings.assistant.sources[sourceType]?.find((v) => v.id === 'internal-search' && (v.include || []).length > 0)) {
                updateSettings({
                    settings: {
                        ...settings,
                        assistant: {
                            ...settings.assistant,
                            sources: {
                                ...settings.assistant.sources,
                                [sourceType]: [{ id: 'internal-search' }, { id: 'web-search' }]
                            },
                            focusedAnalysis: {
                                ...settings.assistant.focusedAnalysis,
                                [sourceType]: false
                            }
                        }
                    }
                })
            }
        }
    }, [])

    useEffect(() => {
        setDossierBreadcrumbPosition(dossierBreadcrumbRef.current?.getBoundingClientRect() || null)
    }, [dossierBreadcrumbRef.current?.getBoundingClientRect()])

    const expertModeAvailable = false;

    const chatboxContainerStyle = `w-[calc(100%-64px)] sm:w-full fixed bottom-0 left-0 right-0 sm:left-auto sm:right-auto max-w-[calc(100%-64px)] md:max-w-[710px] mx-8 sm:mx-0 z-[3]`

    const handleRetry = useCallback((message: UserMessage, idx: number) => {
        conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
        const userMessage = conversation[idx - 1] as UserMessage

        if (userMessage) {
            const conversationId = conversation.at(-1)?.conversationId

            if (!conversationId) return
            dispatch(assistantActions.removeLastMessage({ conversationId }))
            dispatch(deleteLastMessage(conversationId))

            handleAsk({ message: message.query, mode: settings.assistant.mode })
        }
    }, [handleAsk, dispatch, conversation, settings.assistant.mode])

    const handleFollowUpQuestionClick = useCallback((q: string) => {
        conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
        handleAsk({ message: q, mode: settings.assistant.mode })
    }, [handleAsk, settings.assistant.mode])

    const handleCitationSelection = (citation: Citation | null, scrollTop: number) => {
        setOpenedCitation(citation)
        setSelectedSource(null)
        // 60 + 40 from navbar height + top content padding
        const adjustedScrollTop = scrollTop
        setOpenedCitationScrollTop(adjustedScrollTop)
    }

    const handleSourceClick = (source: SourceDocument) => {
        setSelectedSource(source)
    }

    const handleBackgroundClick = () => {
        setOpenedCitation(null)
    }

    return (
        <>
            <div className={`relative flex gap-4 justify-center`} onClick={handleBackgroundClick}>
                <PastChatsContainer />

                <div className={`max-w-[710px] md:w-[710px] w-fit`}>
                    {dossier && shouldShowBreadcrumb && prevScrollY !== 0 && (
                        <div className={`fixed top-[60px] bg-system-surface z-[2]`} style={{ backgroundImage: `url(${texture})`, width: dossierBreadcrumbPosition?.width, left: dossierBreadcrumbPosition?.left }}>
                            <DossierBreadcrumb
                                dossier={dossier}
                                component={
                                    <TypographyBody isStrong={true} className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[400px]">
                                        {title || 'New chat'}
                                    </TypographyBody>
                                }
                            />
                        </div>
                    )}

                    {dossier && (
                        <div ref={dossierBreadcrumbRef} className="bg-system-surface w-full z-[2] -mt-10" style={{ backgroundImage: `url(${texture})` }}>
                            <DossierBreadcrumb
                                dossier={dossier}
                                component={
                                    <TypographyBody isStrong={true} className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[400px]">
                                        {title || 'New chat'}
                                    </TypographyBody>
                                }
                            />
                        </div>
                    )}

                    <div className={`${dossier ? 'pt-7' : ''}`}>
                        {conversation
                            .map((chatMessage, idx) => {
                                if (chatMessage.role === 'user') {
                                    return <UserChatMessage key={`user__${idx}`} message={chatMessage} />
                                }
                                if (chatMessage.role == 'system') {
                                    const question = (conversation[idx - 1] as UserMessage)

                                    return (
                                        <div key={`system_${idx}`} >
                                            <SystemChatMessageContainer
                                                message={chatMessage}
                                                onFollowUpQuestionClick={handleFollowUpQuestionClick}
                                                onRetry={() => handleRetry(question, idx)}
                                                showFollowUpQuestions={idx === conversation.length - 1}
                                                canRetry={conversation.length - 1 === idx}
                                                question={question.query}
                                                sourceType={sourceType}
                                                openedCitation={openedCitation}
                                                onCitationOpen={handleCitationSelection}
                                            />
                                            {idx !== conversation.length - 1 && (
                                                <div className="mt-6 mb-8">
                                                    <Divider />
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                return null
                            })}

                        {queryState.error && (
                            <ErrorMessage message={`We failed to stream the response: ${queryState.error}. Try asking again`} />
                        )}

                        {askExpired && (
                            <ErrorMessage message="missing response. If you refreshed the page, wait a minute and refresh. Otherwise, continue to ask further questions." />
                        )}
                    </div>
                    <div className={`h-[145px] w-full`} ref={conversationBottomRef}></div>
                    <div style={{ height: `${window.innerHeight * 0.2}px` }} className="bg-transparent"></div>
                    <div className={`h-[1px] w-full bg-transparent`} ref={conversationBottomMarginRef}></div>

                    <div className={chatboxContainerStyle}>
                        <ChatBox
                            expertModeAvailable={expertModeAvailable}
                            initialMessage=""
                            handleSubmit={(v) => {
                                conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                setTimeout(() => {
                                    handleAsk(v)
                                }, 100)
                            }}
                            isConversation={true}
                            showBorder={!isChatBoxCoveringText}
                            additionalControls={true}
                            conversationId={lastMessage?.conversationId}
                            isFinished={!isBusy}
                            canStop={true}
                            sourceType={dossier || dossierDetail ? 'dossier' : 'ask'}
                            dossierDetail={dossierDetail}
                        />
                    </div>
                </div>

                <div ref={sourceContainerRef} className={`${citationDocuments.length > 0 || selectedSource ? 'w-[500px] laptop:w-[640px] max-w-[calc(100vw-240px-710px-24px)]' : 'w-0'} relative transition-width ease-in-out duration-300`}>
                    <div className={`absolute left-0 top-0 w-[500px] laptop:w-[640px] max-w-[calc(100vw-240px-710px+48px)] ease-in-out duration-300 ${selectedSource ? 'hidden' : ''}`} style={{ marginTop: `${openedCitationScrollTop}px` }}>
                        <Sources documents={uniqueCitationDocuments} showTabs={false} onSourceClick={handleSourceClick} previewable={true} />
                    </div>

                    {selectedSource && openedCitationResource && (
                        <div className="fixed right-[52px] top-[92px]" style={{ width: `${sourceContainerWidth + window.innerWidth - sourceContainerRight - 52}px` }}>
                            <DocumentPreviewContainer
                                key={`document-preview-container-${selectedSource.document_id}`}
                                type='ask'
                                resource={openedCitationResource}
                                selectedExtractIndex={selectedExtractIndex[selectedSource.document_id] || 0}
                                setSelectedExtractIndex={(index) => {
                                    setSelectedExtractIndex({
                                        ...selectedExtractIndex,
                                        [selectedSource.document_id]: index
                                    })
                                }}
                                sources={uniqueCitationDocuments}
                                selectedSource={selectedSource}
                                initialWidth={window.innerWidth > 1920 ? 600 : 400}
                                onBack={() => setSelectedSource(null)}
                                onClose={() => {
                                    setSelectedSource(null)
                                    setOpenedCitation(null)
                                }}
                                setSelectedSource={(v) => setSelectedSource(v)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <WarnOnNavigate
                open={blocker.state === 'blocked'}
                onConfirm={() => {
                    setShouldResetBlocker(false)
                    blocker?.proceed?.()
                    dispatch(assistantActions.setStreamStatus({ status: StreamStatus.Ready }))
                    // fixes "router allows one blocker at a time" error
                    setTimeout(() => {
                        blocker?.reset?.()
                    }, 500)
                }}
                onCancel={() => {
                    setTimeout(() => {
                        if (shouldResetBlocker) {
                            blocker?.reset?.()
                        }

                        setShouldResetBlocker(true)
                    }, 1)
                }}
            />
        </>
    )
})
