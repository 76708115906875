import { Citation, SourceDocument, SourceDocumentId } from "../../types/types";
import { Skeleton } from "@/components/ui/skeleton";
import { DotIcon } from "lucide-react";
import { embedCharts, embedCitationsV2, embedCitationsV3 } from "../../utils/embedCitations";
import Markdown from "markdown-to-jsx";
import { ResponsiveContainer } from "./ResponsiveContainer";
import { Sources } from "./Sources";
import { CustomTooltip } from "../CustomTooltip";
import { TypographyP } from "../ui/Typography";
import { memo, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ResponseChartContainer, ResponseChartError } from "../Charts/ResponseChart";
import { handleError } from "@/utils/handleError";
import { StyledTable } from "./StyledTable";
import { StyledH4 } from "./StyledH4";
import { getUniqueDocumentIds } from "@/utils/utils";
import { tertiaryStyle } from "../ui/button";

type Props = {
    isLoading: boolean;
    text: string;
    isComplete: boolean;
    documents: SourceDocument[];
    citations: Citation[];
    compact?: boolean;
    legacyCitation?: boolean;
    openedCitation?: Citation | null;
    onCitationOpen?: (citation: Citation | null, scrollTop: number) => void;
}

interface AnswerBlockProps {
    text: string;
    citations: Citation[];
    documents: SourceDocument[];
    isComplete: boolean;
    compact?: boolean;
    openedCitation?: Citation | null;
    onCitationOpen?: (citation: Citation | null, scrollTop: number) => void;
}

interface CitedTextProps {
    text: string;
    citations: Citation[];
    documents: SourceDocument[];
    isComplete: boolean;
    compact?: boolean;
    openedCitation?: Citation | null;
    onCitationOpen?: (citation: Citation | null, scrollTop: number) => void;
}

interface LegacyAnswerBlockProps {
    text: string;
    citations: Citation[];
    documents: SourceDocument[];
    isComplete: boolean;
    compact?: boolean;
    onCitationOpen: (id: string, documentIds: string[]) => void;
}

interface LegacyCitedTextProps {
    text: string;
    citations: Citation[];
    documents: SourceDocument[];
    isComplete: boolean;
    compact?: boolean;
    onCitationOpen: (id: string, documentIds: string[]) => void;
}

interface CursorProps {
    show: boolean;
}

export function LoadingBlocks() {
    return (
        <ResponsiveContainer>
            <Skeleton className="w-4/6 h-[20px] my-2" />
            <Skeleton className="w-full h-[20px] my-2" />
            <Skeleton className="w-full h-[20px] my-2" />
            <Skeleton className="w-5/6 h-[20px] my-2" />
        </ResponsiveContainer>
    )
}

const LegacyCitedText = memo(({ text, citations, documents, isComplete, compact, onCitationOpen }: LegacyCitedTextProps) => {
    const embeddedCitations = embedCitationsV2({ text, citations, highlightCitation: "", documents, isComplete });
    const embeddedCharts = embedCharts({ text: embeddedCitations })

    const Cite = memo(({ children, documentIds, highlight }: { children: string, documentIds: string, highlight: boolean, onSelectCitation: (s: SourceDocumentId[]) => void }) => {
        try {
            const uuid = crypto.randomUUID().split('-')[0]
            const classes = highlight ? "" : "!font-medium hover:cursor-pointer text-black z-[0]";
            const containerClasses = highlight ? "" : "citation-background"
            const parsedDocumentIds: string[] = JSON.parse(documentIds || "[]");

            return (
                <span className={containerClasses}>
                    <span
                        className={`relative ${compact ? 'font-medium' : 'font-medium'} ${classes}`}
                        data-tooltip-place="bottom"
                        id={`cited-text-${uuid}`}
                        onClick={() => onCitationOpen(uuid, parsedDocumentIds)}
                    >
                        {children}
                    </span>
                </span>
            )
        } catch (e) {
            handleError(e)
            return children;
        }
    })

    const chartDocuments = documents.filter((v) => v.document_id.includes('chart')).length

    // needs memo to prevent rerender
    const Chart = useMemo(() => ({ id }: { id: string }) => {
        try {
            const document = documents.find((v) => v.document_id === id)
            if (!document) {
                throw new Error('no chart document found')
            }

            return (
                <ResponseChartContainer key={`response-chart-container-${id}`} id={id} data={document.text} compact={compact} />
            )
        } catch {
            return <ResponseChartError />
        }
    }, [chartDocuments])

    return (
        <Markdown options={{
            overrides: {
                Cite: {
                    component: Cite
                },
                Cursor: {
                    component: Cursor
                },
                p: {
                    component: ({ children }: { children: ReactNode }) =>
                        <>
                            <TypographyP className={`mb-2 mt-6 first:!mt-0 ${compact ? 'font-label' : 'font-body'}`}>{children}</TypographyP>
                        </>
                },
                h1: {
                    component: StyledH4
                },
                h2: {
                    component: StyledH4
                },
                h3: {
                    component: StyledH4
                },
                h4: {
                    component: StyledH4
                },
                h5: {
                    component: StyledH4
                },
                h6: {
                    component: StyledH4
                },
                li: {
                    component: ({ children }: { children: ReactNode }) => <li className={`${compact ? 'font-label' : 'font-body'} my-3 ml-10`}>{children}</li>
                },
                table: {
                    component: StyledTable
                },
                strong: {
                    component: ({ children }: { children: ReactNode }) => <span className={`text-system-primary font-medium`}>{children}</span>
                },
                pre: {
                    component: ({ children }: { children: ReactNode }) => <pre>{children}</pre>
                },
                Chart: {
                    component: Chart
                }
            },
            wrapper: 'span',
            forceBlock: true,
        }}
        >
            {embeddedCharts}
        </Markdown>
    )
})

const CitedText = memo(({ text, citations, documents, isComplete, compact, openedCitation, onCitationOpen }: CitedTextProps) => {
    const embeddedCitations = embedCitationsV3({ text, citations, highlightCitation: "", documents, isComplete });
    const embeddedCharts = embedCharts({ text: embeddedCitations })

    const Cite = memo(({ children, citation }: { children: string, citation: string }) => {
        const uuid = useRef(crypto.randomUUID().split('-')[0]).current

        try {
            const ref = useRef<HTMLSpanElement>(null)
            const parsedCitation: Citation = JSON.parse(citation);
            const citationWithHighlights = citations.find((v) => parsedCitation.end === v.end &&
                parsedCitation.start === v.start &&
                parsedCitation.text === v.text)

            const isSelected = parsedCitation.end === openedCitation?.end &&
                parsedCitation.start === openedCitation.start &&
                parsedCitation.text === openedCitation.text
            const classes = "!font-medium hover:cursor-pointer text-black z-[0]";
            const containerClasses = isSelected ? 'bg-[linear-gradient(0deg,_var(--citation-hover),_var(--citation-hover))] bg-no-repeat bg-[length:100%_90%]' : "citation-background"

            const handleClick = (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                if (isSelected) {
                    onCitationOpen?.(null, 0)
                } else {
                    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    if (citationWithHighlights) {
                        if (openedCitation) {
                            onCitationOpen?.(
                                citationWithHighlights,
                                ref.current?.offsetTop || 0
                            )
                        } else {
                            requestAnimationFrame(() => {
                                onCitationOpen?.(
                                    citationWithHighlights,
                                    ref.current?.offsetTop || 0
                                )
                            });
                        }
                    }
                }
            }

            return (
                <span className={containerClasses}>
                    <span
                        ref={ref}
                        className={`relative ${compact ? 'font-medium' : 'font-medium'} ${classes}`}
                        id={`cited-text-${uuid}`}
                        onClick={handleClick}
                    >
                        {children}
                    </span>
                </span>
            )
        } catch (e) {
            handleError(e)
            return children;
        }
    })

    const chartDocuments = documents.filter((v) => v.document_id.includes('chart')).length

    // needs memo to prevent rerender
    const Chart = useMemo(() => ({ id }: { id: string }) => {
        try {
            const document = documents.find((v) => v.document_id === id)
            if (!document) {
                throw new Error('no chart document found')
            }

            return (
                <ResponseChartContainer key={`response-chart-container-${id}`} id={id} data={document.text} compact={compact} />
            )
        } catch {
            return <ResponseChartError />
        }
    }, [chartDocuments])

    return (
        <Markdown options={{
            overrides: {
                Cite: {
                    component: Cite
                },
                Cursor: {
                    component: Cursor
                },
                p: {
                    component: ({ children }: { children: ReactNode }) =>
                        <>
                            <TypographyP className={`mb-2 mt-6 first:!mt-0 ${compact ? 'font-label' : 'font-body'}`}>{children}</TypographyP>
                        </>
                },
                h1: {
                    component: StyledH4
                },
                h2: {
                    component: StyledH4
                },
                h3: {
                    component: StyledH4
                },
                h4: {
                    component: StyledH4
                },
                h5: {
                    component: StyledH4
                },
                h6: {
                    component: StyledH4
                },
                li: {
                    component: ({ children }: { children: ReactNode }) => <li className={`${compact ? 'font-label' : 'font-body'} my-3 ml-10`}>{children}</li>
                },
                table: {
                    component: StyledTable
                },
                strong: {
                    component: ({ children }: { children: ReactNode }) => <span className={`text-system-primary font-medium`}>{children}</span>
                },
                pre: {
                    component: ({ children }: { children: ReactNode }) => <pre>{children}</pre>
                },
                Chart: {
                    component: Chart
                },
                a: {
                    component: ({ href, children }: { href: string, children: ReactNode }) => {
                        return (
                            <a href={href} className={`cursor-pointer underline ${tertiaryStyle} !text-system-body`} target="_blank" rel="noopener noreferrer">
                                {children}
                            </a>
                        )
                    }
                }
            },
            wrapper: 'span',
            forceBlock: true,
        }}
        >
            {embeddedCharts}
        </Markdown>
    )
})

function Cursor({ show }: CursorProps) {
    if (!show) return null;
    return <DotIcon className="w-6 h-6 text-system-body inline" />;
}

const LegacyAnswerBlock = memo(({ text, citations, documents, isComplete, compact, onCitationOpen }: LegacyAnswerBlockProps) => {
    return (
        <ResponsiveContainer>
            <div className={`text-system-body leading-7 ${compact ? "!font-label" : "!font-body"}`}>
                <span className="">
                    <LegacyCitedText
                        text={text}
                        citations={citations}
                        documents={documents}
                        isComplete={isComplete}
                        compact={compact}
                        onCitationOpen={onCitationOpen}
                    />
                </span>
            </div>
        </ResponsiveContainer>
    )
})

const AnswerBlock = memo(({ text, citations, documents, isComplete, compact, openedCitation, onCitationOpen }: AnswerBlockProps) => {
    return (
        <ResponsiveContainer>
            <div className={`text-system-body leading-7 ${compact ? "!font-label" : "!font-body"}`}>
                <span className="">
                    <CitedText
                        text={text}
                        citations={citations}
                        documents={documents}
                        isComplete={isComplete}
                        compact={compact}
                        openedCitation={openedCitation}
                        onCitationOpen={onCitationOpen}
                    />
                </span>
            </div>
        </ResponsiveContainer>
    )
})

export const FinalAnswer = memo(({ isLoading, isComplete, text, citations, documents, compact, legacyCitation, openedCitation, onCitationOpen }: Props) => {
    const [documentIds, setDocumentIds] = useState<string[]>([])
    const [anchor, setAnchor] = useState<string | null>(null)
    const [openTooltip, setOpenTooltip] = useState(false)

    const citationDocuments = documents.filter(d => {
        const uniqueIds = getUniqueDocumentIds(documentIds)
        try {
            let id = "";
            if (d.document_id.includes("python")) {
                return false;
            }

            if (d.document_id.startsWith("web-search")) {
                id = d.document_id;
            } else {
                id = d.document_id.split("_")[0];
            }

            return uniqueIds.includes(id);
        } catch (e) {
            handleError(e)
            return false;
        }
    });

    const onLegacyCitationOpen = useCallback((id: string, documentIds: string[]) => {
        setDocumentIds(documentIds)
        setAnchor(id)
        setOpenTooltip(true)
    }, []);

    const CitationTooltip = () => {
        return (
            <CustomTooltip
                anchorSelect={`[id^='cited-text-${anchor || ""}']`}
                clickable={true}
                openOnClick={true}
                isOpen={openTooltip}
                setIsOpen={(v) => setOpenTooltip(v)}
                onRender={() => {
                    if (citationDocuments.length === 0) return null

                    return (
                        <div className="max-w-100vw sm:max-w-[710px] sm:min-w-[480px] max-h-[310px] overflow-y-auto">
                            <Sources documents={citationDocuments} showTabs={false} />
                        </div>
                    )
                }}
            />
        )
    }

    if (isLoading) {
        return <LoadingBlocks />
    }
    return (
        <>
            {/* Avoid z index issues */}
            {createPortal(<CitationTooltip />, document.body)}

            {!legacyCitation ?
                <AnswerBlock
                    text={text}
                    citations={citations}
                    documents={documents}
                    isComplete={isComplete}
                    compact={compact}
                    openedCitation={openedCitation}
                    onCitationOpen={onCitationOpen}
                />
                :
                <LegacyAnswerBlock
                    text={text}
                    citations={citations}
                    documents={documents}
                    isComplete={isComplete}
                    compact={compact}
                    onCitationOpen={onLegacyCitationOpen}
                />
            }
        </>

    )
})
