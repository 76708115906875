import { ASYNC_STATUS, Dossier } from "@/types/types"
import { Button } from "../ui/button"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog"
import { Input } from "../ui/input"
import { z } from 'zod'
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { deleteDossier, dossierActions, fetchDossiers } from "./dossierSlice"
import { useContext } from "react"
import { ToastContext } from "@/contexts/ToastContext"
import { MiddleTruncate } from "@re-dev/react-truncate"
import { useNavigate } from "react-router-dom"
import { ErrorMessage } from "../ErrorMessage"

const formSchema = z.object({
    confirmation: z.string().regex(new RegExp('Delete', 'i'), 'Please enter "Delete"'),
})

export const DossierDeleteDialog = ({ dossier, open, setOpen }: { dossier: Dossier, open: boolean, setOpen: (open: boolean) => void }) => {
    const deleteDossierStatus = useSelector((state: RootState) => state.dossier.deleteDossier.status)

    const { showToast } = useContext(ToastContext)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const truncatedText = dossier.subject.length > 30 ? dossier.subject.slice(0, 30) + '...' : dossier.subject

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
    })

    const onSubmit = () => {
        handleDelete()
    }

    const handleDelete = async () => {
        setOpen(false)

        navigate('/dossiers')

        const response = await dispatch(deleteDossier(dossier.id))

        if (response.meta.requestStatus === 'fulfilled') {
            showToast({
                descriptionComponent: <div className="w-[250px]">
                    <MiddleTruncate className="text-system-body w-[250px]" end={17}>
                        {`${dossier.subject} has been deleted`}
                    </MiddleTruncate>
                </div>,
                dismissable: true,
            })

            dispatch(dossierActions.removeDossier(dossier))
            dispatch(fetchDossiers())
        }
    }

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="w-full space-y-8 !overflow-visible" onClick={(e) => e.stopPropagation()} handleClose={() => setOpen(false)}>
                <DialogHeader className="">
                    <DialogTitle className="line-clamp-2">{`You are about to delete ${truncatedText} including all of its content`}</DialogTitle>
                    <DialogDescription>
                        This action cannot be undone.
                    </DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 overflow-y-auto px-1 -mx-1">
                        <div>
                            <FormField
                                control={form.control}
                                name="confirmation"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="font-body-strong">{`Enter 'Delete' to confirm deletion`}</FormLabel>
                                        <FormMessage />
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                    </FormItem>
                                )}
                            />
                        </div>

                        {deleteDossierStatus === ASYNC_STATUS.error && (
                            <ErrorMessage message="Failed to delete dossier. Please try again" />
                        )}

                        <DialogFooter className="pt-8">
                            <Button type="submit" variant="secondary-destructive" onClick={() => form.handleSubmit(onSubmit)}>Delete dossier</Button>

                            <DialogClose>
                                <Button>
                                    Keep dossier
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}