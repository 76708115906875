import { Plus } from "lucide-react"
import { Button } from "../ui/button"
import { TypographyBody, TypographyH3 } from "../ui/Typography"
import { ASYNC_STATUS, Dossier } from "@/types/types"
import { Input } from "../ui/input"
import { useEffect, useState } from "react"
import { formatDate, getWeek, setMonth } from "date-fns"
import { DossierCard } from "./DossierCard"
import { DossierCreateDialog } from "./DossierCreateDialog"
import { groupItemsPerMonthAndWeek } from "@/utils/utils"
import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { fetchDossiers } from "./dossierSlice"
import { Skeleton } from "../ui/skeleton"
import { CustomAlert } from "../CustomAlert"

interface DossierPageProps {
    dossiers: Dossier[]
    loading: boolean,
    error: boolean
}

export const DossierPage = ({ dossiers, loading, error }: DossierPageProps) => {
    const [searchText, setSearchText] = useState('')
    const [showCreateDialog, setShowCreateDialog] = useState(false)

    const filteredDossiers = dossiers.filter((v) => {
        if (!searchText) { return true }
        const splitText = searchText.toLowerCase().split(' ')
        return splitText.some((text) => v.subject.toLowerCase().includes(text))
    })

    const sortedDossierGroups = groupItemsPerMonthAndWeek(filteredDossiers, 'updated_at')

    return (
        <>
            <div className="flex flex-col gap-10 mt-10 sm:mt-0 mx-auto">
                <div className="text-center">
                    <TypographyH3>
                        Dossiers
                    </TypographyH3>
                </div>

                {dossiers.length === 0 && !loading && !error && (
                    <div className="flex flex-col gap-4 mx-auto">
                        <TypographyBody className="text-system-body">
                            There are no dossiers yet.
                        </TypographyBody>

                        <Button className="w-fit mx-auto" onClick={() => setShowCreateDialog(true)}>
                            <div className="flex gap-2">
                                <Plus className="size-6 shrink-0 stroke-[1.5px]" />

                                Create dossier
                            </div>
                        </Button>
                    </div>
                )}

                {dossiers.length > 0 && !loading && !error && (
                    <>
                        <div className="flex flex-wrap gap-x-2 gap-y-4 mx-auto justify-center">
                            <Input
                                className="w-[320px]"
                                placeholder="Search by dossier name"
                                isSearch={true}
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                }}
                                isCloseVisible={!!searchText}
                                onCloseClick={() => setSearchText('')}
                            />
                            <Button onClick={() => setShowCreateDialog(true)}>
                                <div className="flex gap-2">
                                    <Plus className="size-6 shrink-0 stroke-[1.5px]" />

                                    Create dossier
                                </div>
                            </Button>
                        </div>

                        <div className="flex flex-col gap-4">
                            {sortedDossierGroups.map((group, idx) => {
                                const monthDate = setMonth(new Date(), group.monthNumber || 0);
                                const currentWeek = getWeek(new Date())
                                const weekText = currentWeek === group.weekNumber ? 'This week' : currentWeek - 1 === group.weekNumber ? 'Last week' : `${currentWeek - (group.weekNumber || 0)} weeks ago`

                                return (
                                    <div className={`flex flex-col gap-2 ${idx > 0 ? 'mt-8' : ''}`} key={idx}>
                                        <TypographyBody className="mx-3">
                                            {group.monthNumber ? formatDate(monthDate, 'MMMM yyyy') : weekText}
                                        </TypographyBody>

                                        {group.items.map((dossier) => <DossierCard key={dossier.id} dossier={dossier} />)}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}

                {loading && !error && (
                    <div className="flex flex-col gap-4">
                        <Skeleton className="w-full h-[90px]"></Skeleton>
                        <Skeleton className="w-full h-[90px]"></Skeleton>
                        <Skeleton className="w-full h-[90px]"></Skeleton>
                        <Skeleton className="w-full h-[90px]"></Skeleton>
                    </div>
                )}

                {error && !loading && (
                    <CustomAlert
                        variant='error'
                        description="We could not load dossiers at this time. Please try again."
                    />
                )}
            </div>

            <DossierCreateDialog open={showCreateDialog} setOpen={(v) => setShowCreateDialog(v)} />
        </>
    )
}

export const DossierPageContainer = () => {
    const dossiers = useSelector((state: RootState) => state.dossier.dossiers)

    const dispatch = useDispatch<AppDispatch>();
    const loading = dossiers.status === ASYNC_STATUS.loading
    const error = dossiers.status === ASYNC_STATUS.error

    useEffect(() => {
        dispatch(fetchDossiers())
    }, [])

    useEffect(() => {
        document.title = `Desia AI - Dossiers`
    }, [])

    return (
        <DossierPage dossiers={dossiers.data || []} loading={loading} error={error} />
    )
}