import ArrowRight from "@/assets/ArrowRight"
import { Button } from "../ui/button"
import { TypographyBody, TypographyH3 } from "../ui/Typography"
import { useNavigate } from "react-router-dom"
import DocGenHomeImage from '../../assets/img-home-doc-gen.svg'
import ImgNewAsk from '../../assets/img-new-ask.svg'

export const HomeActivityCard = ({ type }: { type: 'ask' | 'report' }) => {
    const navigate = useNavigate()

    return (
        <>
            <div className="group h-[320px] w-full">
                <Button
                    variant='secondary'
                    size='fit'
                    className="p-0 flex flex-col h-full w-full items-start relative overflow-hidden !rounded-lg"
                    onClick={() => {
                        if (type === 'ask') {
                            navigate('/assistant/ask')
                        } else {
                            navigate('/reports')
                        }
                    }}
                >
                    <img className={`absolute ${type === 'ask' ? 'w-[443px]' : 'w-[443px]'} z-1 top-8 left-0 right-0 mx-auto px-4`} src={type === 'ask' ? ImgNewAsk : DocGenHomeImage} />
                    <div className="flex flex-col gap-1 p-5 mt-auto w-[calc(100%-8px)] z-[2] m-1 rounded-md transition-colors">
                        <TypographyH3 className="z-[2] w-full text-left">{type === 'ask' ? 'Ask Desia' : 'Create report'}</TypographyH3>
                        <div className="flex gap-2 w-full">
                            <TypographyBody className="w-full text-left text-system-body">
                                {type === 'ask' ? 'Browse or start a new chat' : 'View or create a new report'}
                            </TypographyBody>
                            <ArrowRight />
                        </div>
                    </div>
                </Button>
            </div>
        </>
    )
}