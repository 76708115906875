import { UserControlledFeatureFlags } from "./UserControlledFeatureFlags";
import { AssistantConfig } from "./AssistantConfig";
import { useContext, useEffect } from "react";
import { FeatureFlagContext } from "../contexts/FeatureFlagContext";
import { TypographyH3 } from "./ui/Typography";
import { Card, CardContent, CardHeader } from "./ui/card";

export function Settings() {
    const { checkFlag } = useContext(FeatureFlagContext);
    const showModelParams = checkFlag("assistant: model parameters");

    useEffect(() => {
        document.title = 'Desia AI - Settings'
    }, [])
    
    return (
        <div>
            <div className="text-center">
                <TypographyH3>Settings</TypographyH3>
            </div>

            <Card className="my-6">
                <CardHeader>Feature flags</CardHeader>
                <CardContent>
                    <UserControlledFeatureFlags />
                </CardContent>
            </Card>

            {showModelParams && (
                <Card>
                    <CardHeader>Assistant parameters</CardHeader>
                    <CardContent>
                        <AssistantConfig />
                    </CardContent>
                </Card>
            )}
            <br />
        </div>
    )
}
