import { formatNumberString, getFinalNodeChildren, isInvisibleWhitespace } from "@/utils/utils";
import React, { cloneElement, isValidElement, ReactNode } from "react";
import { Table, TableBodyStyle, TableCaptionStyle, TableCellStyle, TableFooterStyle, TableHeadStyle, TableRowStyle } from "../ui/table";
import { CustomAlert } from "../CustomAlert";
import { handleError } from "@/utils/handleError";

export const StyledTable = ({ children }: { children: ReactNode }) => {
    try {
        const applyClassesRecursively = (element: ReactNode): ReactNode => {
            if (isValidElement(element)) {
                let className = element.props?.className || '';
                let isRightAligned = false

                const childrenArray = children as []

                // @ts-expect-error
                const firstRow = childrenArray?.find((v: ReactNode) => v.type === 'tbody')?.props?.children[0]
                if (element.type !== 'th') {
                    const elementText = element?.props.children?.length > 0 && element.props.children?.[0]

                    // @ts-expect-error
                    const mappedRows: string[][] = childrenArray?.find((v: ReactNode) => v.type === 'tbody')?.props?.children?.map((v) => getFinalNodeChildren(v))
                    const currentRow: string[] = mappedRows.find((v) => v.includes(elementText))?.filter((v) => !isInvisibleWhitespace(v)) || []

                    const index = currentRow.findIndex((v) => v === elementText)
                    // @ts-expect-error
                    const cell = firstRow?.props.children?.[index > 0 ? index : parseInt(element.key)]

                    const text = cell?.props.children?.length > 0 && cell.props.children?.filter((v: string | ReactNode) => (typeof v === 'string' && !isInvisibleWhitespace(v)) || typeof v !== 'string')[0]

                    const removedText = typeof text === 'string' ? formatNumberString(text) : typeof text === 'object' ? formatNumberString(getFinalNodeChildren(text)[0] as string) : text
                    const isNumber = /^\d+$/.test(removedText)
                    if (isNumber) {
                        isRightAligned = true
                    }
                }

                if (element.type === 'th') {
                    const childrenArray = children as []
                    // @ts-expect-error
                    const headerTr = childrenArray?.find((v: ReactNode) => v.type === 'thead')?.props?.children
                    const index = headerTr?.props.children?.findIndex((v: ReactNode) => v === element)

                    const cell = firstRow?.props.children?.[index]
                    const text = cell?.props.children?.length > 0 && cell.props.children?.filter((v: string | ReactNode) => (typeof v === 'string' && !isInvisibleWhitespace(v)) || typeof v !== 'string')[0]

                    const removedText = typeof text === 'object' ? formatNumberString(getFinalNodeChildren(text)[0] as string) : typeof text === 'string' ? formatNumberString(text) : text

                    const isNumber = /^\d+$/.test(removedText)

                    if (isNumber) {
                        isRightAligned = true
                    }
                }

                switch (element.type) {
                    case 'th':
                        className = `${TableHeadStyle} first:!text-left bg-system-hover px-4 [&_th]:px-4 [&_th]:py-2 [&_th]:font-body-strong ${isRightAligned && '!text-right'}`;
                        break;
                    case 'tr':
                        className = `${TableRowStyle}`;
                        break;
                    case 'td':
                        className = `${TableCellStyle} !px-4 !py-2 bg-system-secondary border-t border-system-border-light first:!text-left ${isRightAligned && '!text-right'}`;
                        break;
                    case 'tbody':
                        className = `${TableBodyStyle}`;
                        break;
                    case 'tfoot':
                        className = TableFooterStyle;
                        break;
                    case 'caption':
                        className = TableCaptionStyle;
                        break;
                    default:
                        break;
                }

                const nestedChildren = element.props.children
                    ? React.Children.map(element.props.children, applyClassesRecursively)
                    : element.props.children;

                return cloneElement(element, {
                    // @ts-expect-error
                    className: className.trim(),
                    children: nestedChildren,
                });
            }
            return element;
        }

        const enhancedChildren = React.Children.map(children, applyClassesRecursively)

        return (
            <div className="relative rounded-lg">
                <div className="border border-system-border-regular rounded-lg overflow-hidden my-8">
                    <Table className="rounded-lg overflow-visible">
                        {enhancedChildren}
                    </Table>
                </div>
            </div>
        )
    } catch (e) {
        handleError(e)
        return (
            <div className="flex flex-col gap-4 p-4 mt-10">
                <CustomAlert
                    variant='error'
                    title="We could not load the table at this time"
                    description="We have notified the team and will be fixing the issue soon."
                />
            </div>
        )
    }
}