export const WEB_SERVER_ENDPOINT = import.meta.env.VITE_WEB_SERVER_ENDPOINT;
export const DEBUG_MODE = import.meta.env.VITE_DEBUG_MODE;
export const LOGIN_PATH = import.meta.env.VITE_LOGIN_PATH;
export const LOGOUT_PATH = import.meta.env.VITE_LOGOUT_PATH;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
export const DISABLE_SENTRY = import.meta.env.VITE_DISABLE_SENTRY; // for local dev

// feature flags
export const FF_CHAT = import.meta.env.VITE_FF_CHAT;
export const FF_THREADS = import.meta.env.VITE_FF_THREADS;
export const FF_WARN_ON_NAVIGATE = import.meta.env.VITE_FF_WARN_ON_NAVIGATE; // during active conversation

// user controlled feature flags
export const USER_FEATURE_FLAG_PREFIX = "user_flag";
export const userFeatureFlags = [
  "assistant: model parameters",
  "docgen: ask",
  "files: legacy upload",
  "docgen: entity extraction",
  "docgen: factCheck primary entity context",
  "docgen: factCheck regen answer",
  "assistant: tools",
  "integration: manage",
] as const;

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export const DEFAULT_CACHE_TIMEOUT_MS = 1_000 * 60 * 5; // 5minutes
export const LOCAL_STORAGE_TIMEOUT_MS = 1_000 * 60 * 60 * 24 * 3; // 3days

export const RECENT_THREADS_LIMIT = 5;
export const STREAM_RESPONSE_TIMEOUT_MS = 1_000 * 30;
export const MESSAGE_ASSUMED_EXPIRED_MS = 1_000 * 60;

export const USER_SETTINGS_KEY = "user_settings";
export const MAX_ICONS_INLINE_SOURCES = 5;
export const MAX_UPLOAD_FILES = 20;
export const POLL_FILES_DURATION = 5_000;
export const CHART_MIN_TICK_GAP = 12

export enum Color {
  'system-border-regular' = '#C8D3DF',
  'system-border-light' = '#E5ECF4',
  'messaging-negative-background' = '#FEECE2',
  'messaging-negative-text' = '#B50021',
  'system-chart-1' = '#273474',
  'system-chart-2' = '#77D9B6',
  'system-chart-3' = '#9E54AE',
  'system-chart-4' = '#F56A63',
  'system-chart-5' = '#FFE680',
  'system-chart-6' = '#96B767',
  'system-chart-7' = '#BEC4FF',
  'system-chart-8' = '#DD2F3D',
  'system-chart-9' = '#FDBFD0',
  'system-chart-10' = '#C0F9FD',
  'system-body' = '#404C5E'
}

export const reactPDFOptions = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
  cMapPacked: true,
}