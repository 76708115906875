import "./Profiles.css";
import { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import { TypographyBody, TypographyH3 } from "./ui/Typography";
import { Input } from "./ui/input";
import { friendlyOrgName } from "@/utils/utils";

export function Profile() {
    const user = useContext(UserContext);
    
    useEffect(() => {
        document.title = 'Desia AI - Profile'
    }, [])

    if (!user.user) return "";
    const orgId = user.user.app_metadata.organization_id;
    const orgName = friendlyOrgName(orgId);

    return (
        <div>
            <div className="text-center">
                <TypographyH3>My Profile</TypographyH3>
            </div>

            <div className="profile-details-container">
                <label htmlFor="profile-username">
                    <TypographyBody className="mb-2">Name</TypographyBody>
                </label>
                <Input readOnly id="profile-username" value={user.user.nickname} className="mb-8" disabled></Input>


                <label htmlFor="profile-email">
                    <TypographyBody className="mb-2">E-mail</TypographyBody>
                </label>
                <Input readOnly id="profile-email" value={user.user.email} className="mb-8" disabled></Input>


                <label htmlFor="profile-organization">
                    <TypographyBody className="mb-2">Organisation</TypographyBody>
                </label>
                <Input readOnly id="profile-organization" value={orgName} className="mb-8" disabled></Input>


                <label htmlFor="profile-userid">
                    <TypographyBody className="mb-2">User ID</TypographyBody>
                </label>
                <Input readOnly id="profile-userid" value={user.user.sub} className="mb-8" disabled></Input>

            </div>
        </div>
    )
}
