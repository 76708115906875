import { Integration, IntegrationStatus } from "@/types/types"
import { TypographyBody, TypographyH3 } from "../ui/Typography"
import { IntegrationCard } from "./IntegrationCard"
import { AppDispatch, RootState } from "@/store/store"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect } from "react"
import { fetchIntegrations, fetchIntegrationStatus } from "./integrationSlice"
import { checkIntegrationFlag } from "@/utils/utils"
import { FeatureFlagContext } from "@/contexts/FeatureFlagContext"
import { UserContext } from "@/contexts/UserContext"

export interface IntergrationPageProps {
    integrations: Integration[]
    integrationStatus: {
        [id: number]: {
            data: IntegrationStatus | null,
            status: 'idle' | 'loading' | 'error' | 'success'
        }
    }
}

export const IntegrationPage = ({ integrations, integrationStatus }: IntergrationPageProps) => {
    const connectingIntegrations = integrations.filter((v) => {
        const detail = integrationStatus[v.integration_id]?.data?.synchronization_details

        if (!detail?.files_to_synchronize_count) return false

        return detail.available_count + detail.failed_count !== detail.files_to_synchronize_count
    })
    const errorIntegrations = integrations.filter((v) => {
        const detail = integrationStatus[v.integration_id]?.data?.synchronization_details

        if (!detail?.files_to_synchronize_count) return false

        return detail.failed_count > 0 && v.integration_has_setup && !connectingIntegrations.find((i) => i.integration_id === v.integration_id)
    })
    const connectedIntegrations = integrations.filter((v) => v.integration_has_setup && !connectingIntegrations.includes(v) && !errorIntegrations.includes(v))
    const availableIntegrations = integrations.filter((v) => !v.integration_is_enabled)

    return (
        <div className="flex flex-col gap-12 max-w-[600px] mx-auto">
            <div className="text-center">
                <TypographyH3>
                    Integrations
                </TypographyH3>
            </div>

            <div className="flex">
                <div className="flex flex-col gap-16 mx-auto flex-grow">
                    {connectedIntegrations.length === 0 && connectingIntegrations.length === 0 && errorIntegrations.length === 0 && (
                        <TypographyBody className="whitespace-pre-wrap text-system-body text-center">
                            {`There are no connected integrations at the moment.\nConnect a service below.`}
                        </TypographyBody>
                    )}


                    {connectingIntegrations.length > 0 && (
                        <div className="flex flex-col gap-4 w-full">
                            <TypographyBody>
                                Connecting
                            </TypographyBody>

                            <div className="grid grid-cols-1 gap-6">
                                {connectingIntegrations.map((integration) => <IntegrationCard key={integration.integration_id} integration={integration} detail={integrationStatus[integration.integration_id]?.data} />)}
                            </div>
                        </div>
                    )}

                    {(connectedIntegrations.length > 0 || errorIntegrations.length > 0) && (
                        <div className="flex flex-col gap-4">
                            <TypographyBody>
                                Connected
                            </TypographyBody>

                            {errorIntegrations.length > 0 && (
                                <div className="grid grid-cols-1 gap-6">
                                    {errorIntegrations.map((integration) => <IntegrationCard key={integration.integration_id} integration={integration} detail={integrationStatus[integration.integration_id]?.data} />)}
                                </div>
                            )}

                            {connectedIntegrations && (
                                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                                    {connectedIntegrations.map((integration) => <IntegrationCard key={integration.integration_id} integration={integration} detail={integrationStatus[integration.integration_id]?.data} />)}
                                </div>
                            )}
                        </div>
                    )}

                    {availableIntegrations.length > 0 && (
                        <div className="flex flex-col gap-4">
                            <TypographyBody>
                                Available to connect
                            </TypographyBody>

                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                                {availableIntegrations.map((integration) => <IntegrationCard key={integration.integration_id} integration={integration} detail={integrationStatus[integration.integration_id]?.data} />)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const IntegrationPageContainer = () => {
    const integrations = useSelector((state: RootState) => state.integration.integrations)
    const integrationStatus = useSelector((state: RootState) => state.integration.integrationStatus)

    const ff = useContext(FeatureFlagContext)
    const { user } = useContext(UserContext)

    const integrationFlag = checkIntegrationFlag(ff, user)

    const dispatch = useDispatch<AppDispatch>();

    const fetchStatus = (integrations: Integration[]) => {
        integrations.forEach((integration) => {
            dispatch(fetchIntegrationStatus(integration.integration_id))
        })
    };

    useEffect(() => {
        dispatch(fetchIntegrations())
            .then((action) => {
                if (action.meta.requestStatus === 'fulfilled') {
                    const payload = action.payload as Integration[]
                    fetchStatus(payload)
                }
            })
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => fetchStatus(integrations.data || []), 10000)

        return () => clearInterval(intervalId)
    }, [integrations.data]);

    useEffect(() => {
        document.title = 'Desia AI - Integrations'
    }, [])

    if (!integrationFlag) return null

    return (
        <IntegrationPage integrations={integrations.data || []} integrationStatus={integrationStatus} />
    )
}