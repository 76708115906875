import loadingGif from '@/assets/gif-desia-logo-animation.gif'
import texture from '../../assets/bg-texture.png'
import { useEffect, useState } from 'react'

export const LoadingAnimation = () => {
    const [showAnimation, setShowAnimation] = useState(Boolean(localStorage.getItem('show_welcome_animation')))

    useEffect(() => {
        setTimeout(() => {
            setShowAnimation(false)
        }, 3000)

        localStorage.removeItem('show_welcome_animation')
    }, [])

    return (
        <div className={`fixed top-0 left-0 h-screen w-screen bg-system-surface z-[100] justify-center flex items-center ${showAnimation ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity ease-in-out duration-500`} style={{ backgroundImage: `url(${texture})` }}>
            <img src={loadingGif} className="" alt='Loading...' />
        </div>
    )
}