import { ASYNC_STATUS, Dossier, DossierDetail, DossierSource, QueryStatus } from "@/types/types"
import { TypographyBody, TypographyLabel } from "../ui/Typography";
import { Button } from "../ui/button";
import Stack from "@/assets/Stack";
import DocumentSelectorDialog from "../Assistant/DocumentSelectorDialog";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "@/contexts/ToastContext";
import { MiddleTruncate } from "@re-dev/react-truncate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { addDossierSource, fetchDossierDetail, removeAllDossierSources, removeDossierSource } from "./dossierSlice";
import { ErrorMessage } from "../ErrorMessage";
import { FileTable } from "../Integration/FileTable";
import { transformResponseDocuments } from "@/utils/transformResponseDocuments";
import { listDocuments } from '../../components/Document/documentThunk';

export const DossierFiles = ({ dossier, dossierDetail }: { dossier: Dossier, dossierDetail: DossierDetail | null }) => {
    const addDossierSourceStatus = useSelector((state: RootState) => state.dossier.addDossierSource.status);
    const removeDossierSourceStatus = useSelector((state: RootState) => state.dossier.removeDossierSource.status);
    const documentStore = useSelector((state: RootState) => state.document);

    const { showToast } = useContext(ToastContext)
    const dispatch = useDispatch<AppDispatch>()

    const [open, setOpen] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<string[]>([])

    const loading = documentStore.fetchStatus === QueryStatus.FETCHING;
    const filteredDocuments = documentStore.files.filter((v) => selectedFiles.includes(v.document_id)) || []
    const elements = transformResponseDocuments(filteredDocuments)

    useEffect(() => {
        const fileIds = dossierDetail?.sources.map((v) => v.resource_id) || []

        setSelectedFiles(fileIds)
    }, [dossierDetail])

    useEffect(() => {
        if(documentStore.fetchStatus !== QueryStatus.SUCCEEDED) {
          dispatch(listDocuments())
        }
    }, [])

    const handleSave = async (files: string[]) => {
        const sources: DossierSource[] = files.map((v) => {
            const file = documentStore.files.find((f) => f.document_id === v)
            if (!file) return null

            return {
                source_dict_id: file.document_source_desia_dict_id || 1,
                resource_id: v
            }
        }).filter((v): v is DossierSource => Boolean(v))

        if ((dossierDetail?.sources || []).length > 0) {
            await dispatch(removeAllDossierSources(dossier.id))
        }

        await dispatch(addDossierSource({
            dossierId: dossier.id,
            sources: sources
        }))

        await dispatch(fetchDossierDetail(dossier.id))
    }

    const handleRemoveDocument = async ({ id, title }: { id: string, title: string }) => {
        setSelectedFiles(selectedFiles.filter((v) => v !== id))

        const source = dossierDetail?.sources.find((v) => v.resource_id === id)

        if (!source) return

        await dispatch(removeDossierSource({
            dossierId: dossier.id,
            sourceId: source.id
        }))

        if (removeDossierSourceStatus === ASYNC_STATUS.success) {
            showToast({
                descriptionComponent: <div className="w-[550px]">
                    <MiddleTruncate className="text-system-body w-[550px]" end={21}>
                        {`${title} removed from dossier`}
                    </MiddleTruncate>
                </div>,
                dismissable: true,
            })
        }

        await dispatch(fetchDossierDetail(dossier.id))
    }

    return (
        <>

            <div className="flex flex-col gap-10 tablet:max-w-[calc(100vw-220px-240px)] laptop:max-w-[calc(100vw-315px-240px)] w-full">
                <div
                    style={{
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2394A3B8FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
                    }}
                    className={`flex flex-col gap-6 p-6 border-system-placeholder rounded-[12px]`}

                >
                    <div className="flex flex-col gap-2 text-center">
                        <TypographyBody isStrong={true}>
                            Focus dossier content on subject-specific files
                        </TypographyBody>

                        <TypographyLabel className="text-system-body whitespace-pre-wrap">
                            {`By default, Ask Desia explores all files in the Library.\n\nTo focus content of this dossier on specific files, select them below. You\ncan still customize sources for each chat or report. `}
                        </TypographyLabel>
                    </div>

                    <div className="w-fit mx-auto">
                        <DocumentSelectorDialog
                            sourceType='dossier'
                            initialSelection={selectedFiles}
                            onSave={(v) => handleSave(v)}
                            trigger={
                                <Button>
                                    <div className="flex gap-2">
                                        <Stack className="size-6 shrink-0" />

                                        Select files
                                    </div>
                                </Button>
                            }
                            open={open}
                            setOpen={setOpen}
                            isDossierFileSelection={true}
                        />
                    </div>
                </div>

                {documentStore.fetchStatus === QueryStatus.ERROR_FETCHING && !loading && (
                    <ErrorMessage message="We could not load dossier files at this time. Please try again." />
                )}

                {addDossierSourceStatus === ASYNC_STATUS.error && (
                    <ErrorMessage message="Failed to add files. Please try again." />
                )}

                {removeDossierSourceStatus === ASYNC_STATUS.error && (
                    <ErrorMessage message="Failed to remove file. Please try again." />
                )}

                {selectedFiles.length === 0 && (
                    <TypographyBody className="text-system-body mx-auto text-center">
                        No files have been selected for this dossier yet.
                    </TypographyBody>
                )}

                {selectedFiles.length > 0 && (
                    <div className="">
                        <FileTable
                            elements={elements}
                            shownElements={elements}
                            resources={documentStore.files}
                            setElements={() => { }}
                            showCheckbox={false}
                            showHeader={true}
                            type='dossier'
                            loading={loading}
                            error={documentStore.fetchStatus === QueryStatus.ERROR_FETCHING}
                            onRemove={(v) => handleRemoveDocument(v)}
                        />
                    </div>
                )}
            </div>

        </>
    )
}